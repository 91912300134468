// ** Icon imports
import HomeIcon from "mdi-material-ui/Home";
import TaskIcon from "@mui/icons-material/Task";
import LockIcon from "@mui/icons-material/Lock";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HelpIcon from "@mui/icons-material/Help";
import CategoryIcon from "@mui/icons-material/Category";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PublicIcon from "@mui/icons-material/Public";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SchoolIcon from "@mui/icons-material/School";
import { useEffect, useState } from "react";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";
import TitleIcon from "@mui/icons-material/Title";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SubjectIcon from "@mui/icons-material/Subject";
import TableChartIcon from "@mui/icons-material/TableChart";
import WebAssetIcon from "@mui/icons-material/WebAsset";

const SubNavigation = () => {
  const [itSolutionId, setItSolutionId] = useState(null);
  useEffect(() => {
    const id = localStorage.getItem("solutionId");
    setItSolutionId(id);
  }, []);
  return [
    {
      title: "Dashboard",
      icon: HomeIcon,
      path: "/dashboard",
    },
    {
      title: "Overview",
      icon: SubjectIcon,
      children: [
        // {
        //   title: "Overview Main Title",
        //   icon: DescriptionRoundedIcon,
        //   path: `/it-solution-overview-title/${itSolutionId}`,
        // },
        {
          title: "Overview List",
          icon: FormatListBulletedRoundedIcon,
          path: `/it-solution-overview/${itSolutionId}`,
        },

        // {
        //   title: "Overview Download",
        //   icon: DownloadForOfflineRoundedIcon,
        //   path: `/it-solution-overview-download/${itSolutionId}`,
        // },
        // {
        //   title: "Overview Features Title",
        //   icon: TitleIcon,
        //   path: `/it-solution-overview-features-title/${itSolutionId}`,
        // },
        {
          title: "Overview Table",
          icon: TableChartIcon,
          path: `/it-solution-overview-features/${itSolutionId}`,
        },
        {
          title: "Overview Plan",
          icon: LocalOfferRoundedIcon,
          path: `/it-solution-overview-plan/${itSolutionId}`,
        },
      ],
    },
    {
      title: "Features",
      icon: CategoryIcon,

      children: [
        // {
        //   title: "Features Title",
        //   icon: TitleIcon,
        //   path: `/it-solution-features-title/${itSolutionId}`,
        // },
        {
          title: "Features List",
          icon: FormatListBulletedRoundedIcon,
          path: `/it-solution-features/${itSolutionId}`,
        },
        {
          title: "Additional Features",
          icon: ListAltIcon,
          path: `/it-solution-additional-features/${itSolutionId}`,
        },
      ],
    },
    {
      title: "Resources",
      icon: WebAssetIcon,
      path: `/it-solution-resources/${itSolutionId}`,
    },
  ];
};

export default SubNavigation;
