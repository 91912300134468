// export const API_BASE_URL = "http://192.168.29.33:6060";
export const API_BASE_URL = "https://api.dw-its.com";

export const MEDIA_URL = "https://d1hj3r7039erwq.cloudfront.net";
export const ApiEndPoints = {
  AUTH: {
    login: `${API_BASE_URL}/api/admin/login`,
    me: `${API_BASE_URL}/api/admin/profile`,
    forgot: `${API_BASE_URL}/api/admin/forgot-password`,
    verifotp: `${API_BASE_URL}/api/admin/verify-otp`,
    reset: `${API_BASE_URL}/api/admin/reset-password`,
    resend: `${API_BASE_URL}/api/admin/resend-otp`,
  },
  DASHBOARD: {
    count: `${API_BASE_URL}/api/admin/dashboard`,
  },
  AppBar: {
    list: `${API_BASE_URL}/api/admin/home`,
    create: `${API_BASE_URL}/api/admin/home`,
    edit: (id) => `${API_BASE_URL}/api/admin/home/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/home/${id}`,
  },
  ClientCategory: {
    list: `${API_BASE_URL}/api/admin/client-catagory`,
    create: `${API_BASE_URL}/api/admin/client-catagory`,
    edit: (id) => `${API_BASE_URL}/api/admin/client-catagory/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/client-catagory/${id}`,
  },
  ClientCategoryLogo: {
    list: (id) => `${API_BASE_URL}api/admin/client-logo/logo-by-id/${id}`,
    create: `${API_BASE_URL}/api/admin/client-logo`,
    edit: (id) => `${API_BASE_URL}/api/admin/client-logo/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/client-logo/${id}`,
  },
  SuccessStories: {
    list: `${API_BASE_URL}/api/admin/success-stories`,
    create: `${API_BASE_URL}/api/admin/success-stories`,
    edit: (id) => `${API_BASE_URL}/api/admin/success-stories/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/success-stories/${id}`,
  },
  ContactUs: {
    list: `${API_BASE_URL}/api/admin/contact-us`,
    create: `${API_BASE_URL}/api/admin/contact-us`,
    edit: (id) => `${API_BASE_URL}/api/admin/contact-us/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/contact-us/${id}`,
  },
  Career: {
    list: `${API_BASE_URL}/api/admin/career`,
    create: `${API_BASE_URL}/api/admin/career`,
    edit: (id) => `${API_BASE_URL}/api/admin/career/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/career/${id}`,
    deleteResponsibilities: (id) =>
      `${API_BASE_URL}/api/admin/career/res/${id}`,
    deleteJobRequirements: (id) =>
      `${API_BASE_URL}/api/admin/career/job-req/${id}`,
  },
  BLOG: {
    list: `${API_BASE_URL}/api/admin/blog`,
    create: `${API_BASE_URL}/api/admin/blog`,
    edit: (id) => `${API_BASE_URL}/api/admin/blog/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/blog/${id}`,
  },
  ITSolutions: {
    list: `${API_BASE_URL}/api/admin/it-solution`,
    create: `${API_BASE_URL}/api/admin/it-solution`,
    edit: (id) => `${API_BASE_URL}/api/admin/it-solution/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/it-solution/${id}`,
  },
  SubITSolutions: {
    list: (id) => `${API_BASE_URL}/api/admin/sub-it-solution/${id}`,
    create: `${API_BASE_URL}/api/admin/sub-it-solution`,
    edit: (id) => `${API_BASE_URL}/api/admin/sub-it-solution/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/sub-it-solution/${id}`,
  },
  SubITSolutionsList: {
    list: (id) => `${API_BASE_URL}/api/admin/sub-it-solution-list/${id}`,
    create: `${API_BASE_URL}/api/admin/sub-it-solution-list`,
    edit: (id) => `${API_BASE_URL}/api/admin/sub-it-solution-list/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/sub-it-solution-list/${id}`,
  },
  //Overview
  ITSolutionOverview: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-over-view/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-over-view`,
    edit: (id) => `${API_BASE_URL}/api/admin/it-solution-over-view/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/it-solution-over-view/${id}`,
  },
  ITSolutionSubOverview: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-sub-over-view/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-sub-over-view`,
    edit: (id) => `${API_BASE_URL}/api/admin/it-solution-sub-over-view/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/it-solution-sub-over-view/${id}`,
  },
  ITSolutionOverviewMainTitle: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-home-over-view/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-home-over-view`,
    edit: (id) => `${API_BASE_URL}/api/admin/it-solution-home-over-view/${id}`,
    delete: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-home-over-view/${id}`,
  },
  ITSolutionOverviewDownload: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-over-view-pdf/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-over-view-pdf`,
    edit: (id) => `${API_BASE_URL}/api/admin/it-solution-over-view-pdf/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/it-solution-over-view-pdf/${id}`,
  },
  ITSolutionOverviewPlan: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-over-view-plan/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-over-view-plan`,
    edit: (id) => `${API_BASE_URL}/api/admin/it-solution-over-view-plan/${id}`,
    delete: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-over-view-plan/${id}`,
    deleteOverviewPlan: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-over-view-plan/description/${id}`,
  },
  ITSolutionOverviewFeatures: {
    list: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-over-view-feature/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-over-view-feature`,
    edit: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-over-view-feature/${id}`,
    delete: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-over-view-feature/${id}`,
  },
  ITSolutionOverviewFeaturesTitle: {
    list: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-over-view-feature-title/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-over-view-feature-title`,
    edit: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-over-view-feature-title/${id}`,
    delete: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-over-view-feature-title/${id}`,
  },
  //Features
  ITSolutionFeatures: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-feature/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-feature`,
    edit: (id) => `${API_BASE_URL}/api/admin/it-solution-feature/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/it-solution-feature/${id}`,
  },
  ITSolutionFeatureTitle: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-feature-title/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-feature-title`,
    edit: (id) => `${API_BASE_URL}/api/admin/it-solution-feature-title/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/it-solution-feature-title/${id}`,
  },
  ITSolutionSubFeatures: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-sub-feature/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-sub-feature`,
    edit: (id) => `${API_BASE_URL}/api/admin/it-solution-sub-feature/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/it-solution-sub-feature/${id}`,
  },
  ITSolutionAdditionalFeatures: {
    list: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-additional-feature/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-additional-feature`,
    edit: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-additional-feature/${id}`,
    delete: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-additional-feature/${id}`,
  },
  ITSolutionAdditionalFeaturesList: {
    list: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-additional-feature-list/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-additional-feature-list`,
    edit: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-additional-feature-list/${id}`,
    delete: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-additional-feature-list/${id}`,
  },

  //Resources
  ITSolutionResources: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-resources/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-resources`,
    edit: (id) => `${API_BASE_URL}/api/admin/it-solution-resources/${id}`,
    delete: (id) => `${API_BASE_URL}/api/admin/it-solution-resources/${id}`,
  },
  ITSolutionResourcesDetails: {
    list: (id) => `${API_BASE_URL}/api/admin/it-solution-resources-list/${id}`,
    create: `${API_BASE_URL}/api/admin/it-solution-resources-list`,
    edit: (id) => `${API_BASE_URL}/api/admin/it-solution-resources-list/${id}`,
    delete: (id) =>
      `${API_BASE_URL}/api/admin/it-solution-resources-list/${id}`,
  },
};
